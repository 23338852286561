import { Component, Inject, Renderer2, Injector } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/service/api/api.service";
import { DataService } from "src/app/service/data/data.service";
import { AuthService } from "src/app/service/auth/auth.service";
import { UnauthorizePromptService } from "src/app/service/auth/unauthorize-prompt/unauthorize-prompt.service";
import { DOCUMENT } from "@angular/common";
import { UserClaimsService } from "src/app/service/user-claims.service";

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: string;
  pageInput: any;
}

@Component({
  selector: "app-find-patient",
  templateUrl: "./find-patient.component.html",
  styleUrls: ["./find-patient.component.scss"],
})
export class FindPatientComponent {
  state: State = {
    page: 1,
    pageSize: 20,
    searchTerm: "",
    sortColumn: "asc",
    sortDirection: "",
    pageInput: 1,
  };

  nameValue: string;
  apiSubscription: Subscription;
  usersTemp: any;
  patientClaims: any;
  countryVal: string;
  isPatient;
  queryCont;
  patientArr = [];
  total: number = 0;
  patient;
  loading: boolean = true;

  private api: ApiService;
  private data: DataService;
  private auth: AuthService;
  private unAuth: UnauthorizePromptService;
  private actRoute: ActivatedRoute;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private injector: Injector,
    private router: Router,
    public userClaims: UserClaimsService
  ) {
    this.api = this.injector.get<ApiService>(ApiService);
    this.data = this.injector.get<DataService>(DataService);
    this.auth = this.injector.get<AuthService>(AuthService);
    this.unAuth = this.injector.get<UnauthorizePromptService>(UnauthorizePromptService);
    this.actRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);


    this.getCountry();
    this.renderer.removeClass(this.document.body, "init");

    this.userClaims
      .checkUserAccess(this.actRoute.snapshot.data.allowedUsers || [])
      .subscribe((allowed) => {
        if (!allowed) { this.unAuth.showPopup(); }
      });
  }

  getCountry() {
    this.data.currentCountryValue.subscribe((val) => {
      console.log(val);
      this.countryVal = val;
      this.load();
    });
  }

  load() {
    this.loading = true;
    this.patient = [];
    if (this.apiSubscription) this.apiSubscription.unsubscribe();
    const params = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      country: this.countryVal,
      totalCount: this.total,
      SearchText: this.state.searchTerm,
    };

    this.apiSubscription = this.api
      .get("/manage/accounts/patient", params)
      .subscribe(
        (d) => {
          //  d = [] ?
          this.patient = d.users;
          this.loading = false;
          this.total = d.totalCount;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        }
      );
  }

  clear() {
    this.state.searchTerm = "";
    this.load();
  }

  onSelect(userId) {
    this.router.navigate(["/user", userId]);
  }

  get resultRange() {
    if (!this.total) return `${this.total} Result`;
    let start = (this.state.page - 1) * this.state.pageSize + 1;
    let end = (this.state.page - 1) * this.state.pageSize + this.patient.length;
    if (start == end) return `${start} of ${this.total}`;

    return `${start}-${end} of ${this.total}`;
  }

  get maxPage() {
    return Math.ceil(this.total / this.state.pageSize);
  }

  onInput(evt) {
    let page = 1;
    if (evt.target.value === "") {
      //do the opposite condition
    } else {
      page = parseInt(evt.target.value) || 1;
      if (page > this.maxPage) {
        page = this.maxPage;
        evt.target.value = this.maxPage;
        this.state.page = page;
        this.load();
      } else {
        evt.target.value = page;
        this.state.page = page;
        this.load();
      }
    }
  }
  onFocusOut(evt) {
    let page: number = undefined;
    if (evt.target.value === "") {
      evt.target.value = 1;
      page = 1;
      this.state.page = page;
      this.load();
    }
  }

  changePage(op) {
    switch (op) {
      case "first": {
        this.state.page = 1;
        this.state.pageInput = this.state.page;
        break;
      }
      case "last": {
        this.state.page = this.maxPage;
        this.state.pageInput = this.state.page;
        break;
      }
      case "prev": {
        this.state.page = this.state.page - 1;
        this.state.pageInput = this.state.page;
        break;
      }
      case "next": {
        this.state.page = this.state.page + 1;
        this.state.pageInput = this.state.page;
        break;
      }
    }
    this.load();
  }

  get listStatus() {
    if (this.loading) return 1;
    else if (!this.loading && !this.patient.length && this.state.searchTerm)
      return 2;
    else if (!this.loading && !this.patient.length && !this.state.searchTerm)
      return 3;
  }

  onPageChange(evt) {
    this.state.page = evt;
    this.load();
  }
}
