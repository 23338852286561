import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/service/auth/auth.service";
import { UnauthorizePromptService } from "src/app/service/auth/unauthorize-prompt/unauthorize-prompt.service";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { AppState } from "src/app/@store/reducers";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { take, map ,filter, tap} from "rxjs/operators";
import { combineLatest } from "rxjs";
import { UserClaimsService } from "src/app/service/user-claims.service";

@Component({
  selector: "app-login-redirect",
  templateUrl: "./login-redirect.component.html",
})
export class LoginRedirectComponent implements OnInit {
  userTypes$ = this.auth.userInfo$.pipe(map(claims=>claims?.user_type))
  constructor(
    private auth: AuthService,
    private unAuth: UnauthorizePromptService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    public userClaims: UserClaimsService
  ) {

    this.userClaims
      .checkUserAccess(this.actRoute.snapshot.data.allowedUsers || [])
      .subscribe((allowed) => {
        if (!allowed) { this.unAuth.showPopup(); }
      });
  }

  ngOnInit() {
    this.resultCategoryRedirection();
  }

  resultCategoryRedirection() {
    combineLatest([
      this.store.pipe(select(StsSelectors.isEETenant)).pipe(filter(v=>v!==null)),
      this.store.pipe(select(StsSelectors.isHuTenant)).pipe(filter(v=>v!==null)),
      this.store.pipe(select(StsSelectors.isPTAdminUser)).pipe(filter(v=>v!==null))
    ]).pipe(take(1), tap(([isEEtenant, isHuTenant, isPTAdmin])=> {
      const ifPTAdminForHuTenant = isHuTenant && isPTAdmin;
      if(isEEtenant || ifPTAdminForHuTenant) {
        this.router.navigate(["/results", "category"]);
      }
    })).subscribe();
  }
}
