import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SuomiWardSelectorService } from "src/app/shared/component-modules/suomi-ward-selector/suomi-ward-selector.service";
import { localStorageKeys } from "src/app/shared/model/storage.model";

@Component({
  selector: "app-select-suomi-ward",
  standalone: true,
  imports: [],
  templateUrl: "./select-suomi-ward.component.html",
  styleUrl: "./select-suomi-ward.component.scss",
})
export class SelectSuomiWardComponent implements OnInit {
  constructor(
    private readonly suomi: SuomiWardSelectorService,
    private readonly route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const wid: string = this.route.snapshot.queryParamMap.get("wid");

    if (wid) {
      const synlabId: string = atob(wid);
      this.suomi.synlabId$.next(synlabId);
      this.suomi.showResultsForSuomiWard$.next(true);

      localStorage.setItem(
        localStorageKeys.SUOMI_SELECTED_WARD_SYNLAB_ID,
        synlabId
      );
      localStorage.setItem(
        localStorageKeys.HAS_SELECTED_SUOMI_WARD,
        "true"
      );
    }

    this.router.navigateByUrl("/results/category");
  }
}
