export const localStorageKeys = {
  redirect: "App[URLRedirect]",
  langCode: "langCode",
  needReloadForCookiebot: "App[needReloadForCookiebot]",
  SUOMI_SELECTED_WARD_SYNLAB_ID: "weilobjmnwfk",
  HAS_SELECTED_SUOMI_WARD: "bfqcbaxcfzph"
};


export enum EStorageKeys {
  REDIRECT_URL = "APP_REDIRECT_URL"
}
