import { ConfigService } from "src/app/service/config/config.service";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/service/auth/auth.service";
import { DataService } from "src/app/service/data/data.service";
import { ApiService } from "src/app/service/api/api.service";
import { select, Store } from "@ngrx/store";
import { AppState } from "src/app/@store/reducers";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { HeaderActions } from 'src/app/@store/actions/header.actions';
import { combineLatest, Observable } from "rxjs";
import { auditTime, map, shareReplay } from "rxjs/operators";
import { claimValue } from "src/app/appsettings";
import { UserClaimsService } from "src/app/service/user-claims.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  nav = [];
  countryVal;
  c;
  countryClass = "dropdown-item";
  countryClass2 = "dropdown-item";

  availableCountries: any = [];
  userCountries: any = [];

  isAdmin$ = this.store.pipe(select(StsSelectors.isPlasmaWebAdmin));
  canAccessCheckResult$ = this.store.pipe(
    select(StsSelectors.canAccessCheckResults)
  );
  canAccessUserManagement$ = this.store.pipe(
    select(StsSelectors.canAccessUserManagement)
  );
  isCustomerCare$ = this.store.pipe(select(StsSelectors.isCustomerCare));
  isSchool$ = this.store.pipe(select(StsSelectors.isSchoolUser));
  isSchoolAdmin$ = this.store.pipe(select(StsSelectors.isSchoolAdmin));
  isSchoolTeacher$ = this.store.pipe(select(StsSelectors.isSchoolTeacher));
  isLabUser$ = this.store.pipe(select(StsSelectors.isLabUser));
  isMassTester$ = this.store.pipe(select(StsSelectors.isMassTester));
  baseAdminUrl$ = this.store.pipe(select(StsSelectors.getBaseAdminUrl));
  isLolliAdmin$ = this.store.pipe(select(StsSelectors.isLolliAdmin));
  isLolliLabAdmin$ = this.store.pipe(select(StsSelectors.isLolliLabAdmin));
  isDFPAdmin$ = this.store.pipe(select(StsSelectors.isDFPAdmin));
  isSHFYAdmin$ = this.store.pipe(select(StsSelectors.isSHFYAdmin));
  isAppAccessUser$ = this.store.pipe(select(StsSelectors.isAppAccessUser));
  isPreventionWellnessUser$ = this.store.pipe(select(StsSelectors.isPreventionWellnessUser));
  isSuperSupport$ = this.store.pipe(select(StsSelectors.isSuperSupport));
  logo$: Observable<string> = this.store.pipe(
    select(StsSelectors.getAppLogo),
    shareReplay(1)
  );
  isEETenant$: Observable<boolean> = this.store.pipe(
    select(StsSelectors.isEETenant)
  );
  isDFPTenant$: Observable<boolean> = this.store.pipe(select(StsSelectors.isDFPTenant));
  isNotWhiteLabled$ = this.store.pipe(select(StsSelectors.isNotWhiteLabled));
  plasmaAccountUrl$ = this.availableCountries;
  baseProfileUrl$: Observable<string> = this.store.pipe(
    select(StsSelectors.getbaseProfileUrl)
  );
  bookingLinkEnabled$ = this.store.pipe(
    select(StsSelectors.getBookingLinkEnabled)
  );
  bookingUrl$ = this.store.pipe(select(StsSelectors.getBaseBookingUrl));

  capio$ = this.store.pipe(
    select(StsSelectors.canAccesspoolIndividualOrdersPage)
  );

  cagop$ = this.store.pipe(
    select(StsSelectors.canAccesspoolGenerateOrdersPage)
  );

  hasQueryBarcodeLink$ = this.store.pipe(select(StsSelectors.isMassTester));
  hasCreatePoolOrderLink$ = this.store.pipe(select(StsSelectors.isMassTester));
  isILISCodeDE$ = this.store.pipe(select(StsSelectors.isILISCodeDE));
  isAdminUnderDE$ = combineLatest([this.isILISCodeDE$, this.isSchoolAdmin$]).pipe(
    auditTime(10),
    map(allowedRoles => allowedRoles.every(r => r))
  );
  hasUserEditorLink$ = combineLatest([this.isLolliAdmin$, this.isLolliLabAdmin$, this.isAdminUnderDE$]).pipe(
    auditTime(10),
    map(allowedRoles => allowedRoles.some(r => r))
  );

  /** route to landing page of user based on their usertype */
  redirectRoute$ = this.store.pipe(
    select(StsSelectors.getRedirectPath)
  );
  constructor(
    public auth: AuthService,
    private data: DataService,
    private api: ApiService,
    public cnfg: ConfigService,
    private store: Store<AppState>,
    public userClaims: UserClaimsService
  ) {
    this.getFullCountryLabel();
  }
  ngOnInit() {
    this.nav = [];

    this.getCountry();

    //added this just to check if country key still due to refactoring
    this.auth.userInfo.subscribe((d) => {
      if (d) {
        if (d.country != null) {
          this.userCountries =
            typeof d.country === "string" ? [d.country] : d.country;
        } else {
          this.userCountries =
            typeof d["country/locale"] === "string"
              ? [d["country/locale"]]
              : d["country/locale"];
        }
      }
    });
  }

  //set country to active
  activeCountryClass(item) {
    if (
      item.twoLetterCountryCode ==
      this.data.selectedCountry.twoLetterCountryCode
    )
      return true;
    return false;
  }

  getFullCountryLabel() {
    this.api.get("/manage/country").subscribe((c) => {
      this.availableCountries = c;
      if (this.country.length) {
        // set initial value
        this.data.changeValue(this.country[0]);
      }
      // this.userCountries = this.userCountry
    });
  }

  get country() {
    //**must detect why userCountries is in lower case
    return this.availableCountries.filter((d) => {
      if (this.userCountries) {
        if (
          this.userCountries.includes(`${d.twoLetterCountryCode}`.toLowerCase())
        )
          return true;
      }
    });
  }

  get fullCatalogueViewEnabled() {
    return this.cnfg._envConfig.FullCatalogueViewEnabled?.toLowerCase() === claimValue.TRUE
  }

  startSignoutMainWindow() {
    this.auth.startSignoutMainWindow();
  }

  setCountry(item) {
    this.data.changeValue(item);
  }

  getCountry() {
    this.data.currentCountryValue.subscribe((val) => (this.countryVal = val));
  }

  onOpenHeaderMenu() {
    this.store.dispatch(HeaderActions.openMenu())
  }

  onCloseHeaderMenu() {
    this.store.dispatch(HeaderActions.closeMenu())
  }

  get initial() {
    const { profileInfo } = this.auth;
    if (!profileInfo) return "NO";

    const { family_name, given_name, email } = profileInfo;
    const fname = given_name.trim();
    const lname = family_name.trim();
    let str = "";

    if (fname && lname) str = `${fname[0]}${lname[0]}`;
    else if (fname) str = `${fname[0]}${fname[1]}`;
    else if (lname) str = `${lname[0]}${lname[1]}`;
    else str = `${email[0]}${email[1]}`;

    return str.toUpperCase();
  }
}
