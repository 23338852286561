import { Component, EventEmitter, Output } from "@angular/core";
import { AuthService } from "src/app/service/auth/auth.service";
import { localStorageKeys } from "../../model/storage.model";
import { BehaviorSubject, of } from "rxjs";
import { map } from "rxjs/operators";
import { SuomiWardSelectorService } from "./suomi-ward-selector.service";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/@store/reducers";
import { ResultActionsV2 } from "src/app/@store/actions/results.actionsV2";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-suomi-ward-selector",
  templateUrl: "./suomi-ward-selector.component.html",
  styleUrl: "./suomi-ward-selector.component.scss",
})
export class SuomiWardSelectorComponent {
  @Output() toggleSidebar: EventEmitter<any> = new EventEmitter();

  constructor(
    private readonly auth: AuthService,
    public readonly suomiWard: SuomiWardSelectorService,
    private readonly store: Store<AppState>
  ) {}

  get loggedUser() {
    const { profileInfo } = this.auth;
    if (!profileInfo) return "NO";

    return `${profileInfo.given_name} ${profileInfo.family_name}`;
  }

  get emailAddress() {
    const { profileInfo } = this.auth;
    return profileInfo ? profileInfo.email : "";
  }

  switchToParentAccount() {
    this.suomiWard.showResultsForSuomiWard$.next(false);
    localStorage.removeItem(localStorageKeys.HAS_SELECTED_SUOMI_WARD);

    this.toggleSidebar.emit(1);
    setTimeout(() => this.store.dispatch(ResultActionsV2.loadResultssV2()), 100);
  }

  switchToWardAccount() {
    this.suomiWard.showResultsForSuomiWard$.next(true);
    localStorage.setItem(localStorageKeys.HAS_SELECTED_SUOMI_WARD, 'true');
    this.toggleSidebar.emit(1);
    setTimeout(() => this.store.dispatch(ResultActionsV2.loadResultssV2()), 100);
  }

  selectAnotherWardAccount() {
    this.suomiWard.redirectToSuomiSelectWardURL();
  }
}
