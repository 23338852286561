import { Component, Inject, Renderer2 } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/service/api/api.service";
import { DataService } from "src/app/service/data/data.service";
import { AuthService } from "src/app/service/auth/auth.service";
import { UnauthorizePromptService } from "src/app/service/auth/unauthorize-prompt/unauthorize-prompt.service";
import { DOCUMENT } from "@angular/common";
import { UserClaimsService } from "src/app/service/user-claims.service";

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: string;
  pageInput: any;
}

@Component({
  selector: "app-search-patient",
  templateUrl: "./search-patient.component.html",
  styleUrls: ["./search-patient.component.scss"],
})
export class SearchPatientComponent  {
  state: State = {
    page: 1,
    pageSize: 20,
    searchTerm: "",
    sortColumn: "asc",
    sortDirection: "",
    pageInput: 1,
  };

  nameValue: string;
  apiSubscription: Subscription;
  usersTemp: any;
  patientClaims: any;
  countryVal: string;

  isPatient;
  queryCont;
  patientArr = [];
  total: number = 0;
  patient;
  loading: boolean = true;
  showReg;
  sv;
  loadapi;
  isOpen: boolean;
  closeOnSelect: boolean;
  phVal = "Search on Social Security No., Name or Email";

  people;
  pl;
  selectedPersonId;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private api: ApiService,
    private data: DataService,
    private auth: AuthService,
    private unAuth: UnauthorizePromptService,
    private actRoute: ActivatedRoute,
    public userClaims: UserClaimsService
  ) {
    this.showReg = true;
    this.getCountry();
    this.renderer.removeClass(this.document.body, "init");
    this.userClaims
      .checkUserAccess(this.actRoute.snapshot.data.allowedUsers || [])
      .subscribe((allowed) => {
        if (!allowed) { this.unAuth.showPopup(); }
      });
  }


  getCountry() {
    this.data.currentCountryValue.subscribe((val) => {
      this.countryVal = val;
    });
  }

  onSearch(evt) {
    if (evt.term.trim() == "" || evt.term.trim() == null) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
      this.closeOnSelect = true;
    }
  }

  closeOnClick() {
    this.isOpen = false;
  }

  change() {
    console.log("change");
    this.isOpen = false;
  }

  load(event) {
    this.loading = true;
    this.loadapi = true;
    this.patient = [];
    this.pl = [];
    if (this.apiSubscription) this.apiSubscription.unsubscribe();
    const params = {
      page: this.state.page,
      pageSize: 10,
      country: this.countryVal,
      SearchText: this.state.searchTerm,
    };

    this.apiSubscription = this.api
      .get("/manage/accounts/patient", params)
      .subscribe(
        (d) => {
          this.onSearch(event);
          this.patient = d.users;
          this.pl = this.patient
            .filter((df) => {
              return (
                df.emailConfirmed == "True" ||
                df.emailConfirmed == "true" ||
                df.emailConfirmed === true
              );
            })
            .map((dm) => {
              return `${dm.ssn}, ${dm.name}, ${dm.email}`;
            });
          this.loadapi = false;
          this.total = d.totalCount;
          // }
        },
        (error) => {
          this.loadapi = false;
        }
      );
  }

  get listStatus() {
    if (this.loading) return 1;
    else if (!this.loading && !this.patient.length && this.state.searchTerm)
      return 2;
    else if (!this.loading && !this.patient.length && !this.state.searchTerm)
      return 3;
  }

  onPageChange(evt) {
    this.state.page = evt;
    this.load(evt);
  }
}
