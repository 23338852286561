import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, take, withLatestFrom } from "rxjs/operators";
import { STSActions } from "src/app/@store/actions/sts.actions";
import { AppState } from "src/app/@store/reducers";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { claimTypes, claimValue } from "src/app/appsettings";
import { Claim } from "src/app/models/claims.model";
import { ApiService } from "../api/api.service";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class MFAGuard {
  constructor(
    private store: Store<AppState>,
    private api: ApiService,
    private auth: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise((res) => {
      this.auth.userInfo
        .pipe(
          filter((n) => !!n?.userId),
          take(1),
          withLatestFrom(
            this.store.select(StsSelectors.getProfile),
            this.store.select(StsSelectors.getbaseProfileUrl),
            this.store.select(StsSelectors.bypassMFA)
          )
        )
        .subscribe(
          ([d, claims, accountsUrl, bypassMFA]) => {
            // don't call api when claims are available
            if (claims) {
              const shouldActivateMFA = this.shouldActivateMFA( claims, bypassMFA );
              const check: boolean = this.checkpoint( shouldActivateMFA, accountsUrl );
              res(check);
            }
            // call api to get user claims
            else {
              this.api
                .get<Claim[]>(`/manage/accounts/${d.userId}`)
                .pipe(take(1))
                .subscribe(
                  (user) => {
                    // save user's claims in state
                    this.store.dispatch( STSActions.loadProfileSuccess({ claims: user }) );

                    const shouldActivateMFA = this.shouldActivateMFA( user, bypassMFA );
                    const check: boolean = this.checkpoint( shouldActivateMFA, accountsUrl );
                    res(check);
                  },
                  (err) => res(false)
                );
            }
          },
          (err) => res(false)
        );
    });
  }

  /**
   * Returns TRUE, if two_factor_enabled is equal to false and login_method is not equal to TARA
   */
  private shouldActivateMFA(claims: Claim[], bypassMFA: boolean): boolean {
    const isMFAEnabled =
      claims
        .find((n) => n.claimType == claimTypes.TWO_FACTOR_ENABLED)
        ?.claimValue.toLocaleLowerCase() == claimValue.TRUE;

    return !isMFAEnabled && !bypassMFA;
  }

  private checkpoint(shouldActivate: boolean, accountsUrl: string) {
    let userCanProceed: boolean;

    if (shouldActivate) {
      userCanProceed = false;
      window.location.href = `${accountsUrl}/account/two-factor-authentication`;
    } else {
      userCanProceed = true;
    }
    return userCanProceed;
  }
}
