import { createAction, props } from "@ngrx/store";
import { SchoolOption } from "src/app/models/school-option.model";
import { Results, TestType } from "../models/results.model";

const loadResultss = createAction(
  "[Results] Load Resultss",
  props<{ params: any; dateFrom: string; id: string }>()
);

const loadResultssSuccess = createAction(
  "[Results] Load Resultss Success",
  props<{ data: any; total: number; params: any }>()
);

const loadResultssFailure = createAction(
  "[Results] Load Resultss Failure",
  props<{ error: any }>()
);

const loadTestTypeList = createAction("[Results] Load TestType List");
const loadTestTypeListSuccess = createAction(
  "[Results] Load TestType List Success",
  props<{ data: TestType[] }>()
);
const loadTestTypeListFailure = createAction(
  "[Results] Load TestType List Failure",
  props<{ error: any }>()
);
const downloadFile = createAction(
  "[Results] Download File",
  props<{ id: string | number }>()
);
const downloadFileSuccess = createAction(
  "[Results] Download File Success",
  props<{ id: string | number }>()
);
const downloadFileFailure = createAction(
  "[Results] Download File Failure",
  props<{ id: string | number; error: any }>()
);
const loadPdfPreview = createAction(
  "[TeamResult] Load Pdf Preview",
  props<{ url: string; filename: string; fileType: string }>()
);
const loadCheckResults = createAction(
  "[Results] Load Check Results",
  props<{ params: any; isEmailUsed: boolean }>()
);
const loadCheckResultsSuccess = createAction(
  "[Results] Load Check Results Success",
  props<{ list: any; total: number; params: any }>()
);
const loadCheckResultsFailed = createAction(
  "[Results] Load Check Results Failed",
  props<{ error: any }>()
);
const loadSelectedUser = createAction(
  "[Results] Load Selected User",
  props<{ criteria: string }>()
);
const loadSelectedUserSuccess = createAction(
  "[Results] Load Selected User Success",
  props<{ data: any }>()
);
const loadSelectedUserFailed = createAction(
  "[Results] Load Selected User Failed",
  props<{ error: any }>()
);

const loadSelectedUserSynlabId = createAction(
  "[Results] Load Selected User by SYNLAB ID",
  props<{ criteria: string }>()
);
const loadSelectedUserSynlabIdSuccess = createAction(
  "[Results] Load Selected User by SYNLAB ID Success",
  props<{ data: any }>()
);
const loadSelectedUserSynlabIdFailed = createAction(
  "[Results] Load Selected User by SYNLAB ID Failed",
  props<{ error: any }>()
);

const loadLabResults = createAction(
  "[Results] Load Lab Results",
  props<{ testRef: string; testCode: string; lolliSchoolId?: string }>()
);
const loadLabResultsSuccess = createAction(
  "[Results] Load Lab Results Success",
  props<{ id: string }>()
);
const loadLabResultsFailed = createAction(
  "[Results] Load Lab Results Failed",
  props<{ id: string }>()
);

/** load invoice */
const loadInvoice = createAction(
  "[Results] Load Invoice",
  props<{ invoiceId: string; testRef: string }>()
);
const loadInvoiceSuccess = createAction(
  "[Results] Load Invoice Success",
  props<{ id: string }>()
);
const loadInvoiceFailed = createAction(
  "[Results] Load Invoice Failed",
  props<{ id: string }>()
);
/** load school */
const clearLoadSchool = createAction(
  "[Results] Clear Load School"
);
const loadSchool = createAction(
  "[Results] Load School",
  props<{ params: string }>()
);
const loadSchoolSuccess = createAction(
  "[Results] Load School Success",
  props<{ data: SchoolOption[] }>()
);
const loadSchoolFailed = createAction(
  "[Results] Load School Failed",
  props<{ error: any }>()
);
const selectSchool = createAction(
  "[Results] Select School",
  props<{ school: SchoolOption }>()
)

/** load teacher */
const LoadTeacherCancel = createAction(
  "[Results] Load Teacher Cancel"
);
const loadTeacher = createAction(
  "[Results] Load Teacher",
  props<{ schoolSynlabId: string }>()
);
const fetchTeacherSuccess = createAction(
  "[Results] Fetch Teacher Success",
  props<{ data: SchoolOption[] }>()
);
const loadTeacherSuccess = createAction(
  "[Results] Load Teacher Success"
);
const loadTeacherFailed = createAction(
  "[Results] Load Teacher Failed",
  props<{ error: any }>()
);
const setTeacherQuery = createAction(
  "[Results] Set Teacher Query",
  props<{ query: string }>()
);
const selectTeacher = createAction(
  "[Results] Select Teacher",
  props<{ teacher: SchoolOption }>()
);

/** load teacher */
const LoadPoolCancel = createAction(
  "[Results] Load Pool Cancel"
);
const loadPool = createAction(
  "[Results] Load Pool",
  props<{
    schoolSynlabId: string,
    teacherSynlabId: string
  }>()
);
const fetchPoolSuccess = createAction(
  "[Results] Fetch Pool Success",
  props<{ data: SchoolOption[] }>()
);
const loadPoolSuccess = createAction(
  "[Results] Load Pool Success",
);
const loadPoolFailed = createAction(
  "[Results] Load Pool Failed",
  props<{ error: any }>()
);
const setPoolQuery = createAction(
  "[Results] Set Pool Query",
  props<{ query: string }>()
);
const selectPool = createAction(
  "[Results] Select Pool",
  props<{ pool: SchoolOption }>()
);

const forceLoadCertificate = createAction(
  "[Results] Force Load Certificate",
  props<{ refId: any }>()
);

const stopForceLoading = createAction("[Results] Stop Force Loading");

const loadCompanyCheck = createAction(
  "[Results] Load Company Check",
  props<{ poolSynlabIds: string[] }>()
);
const fetchCompanyCheckSuccess = createAction(
  "[Results] fetch Company Check Success",
  props<{
    poolSynlabId: string,
    companyCheckResponse: {
      companyName: string,
      isPoolCompany
    }
  }>()
);
const fetchCompanyCheckFailure = createAction(
  "[Results] fetch Company Check Failure",
  props<{ error: string }>()
);
const loadCompanyCheckDone = createAction(
  "[Results] Load Company Check Done"
);
const companyCheckActions = {
  loadCompanyCheck,
  fetchCompanyCheckSuccess,
  fetchCompanyCheckFailure,
  loadCompanyCheckDone,
}
const checkIfPool = createAction(
  "[Results] Check if pool",
  props<{ synlabId: string }>()
);
const checkIfPoolSuccess = createAction(
  "[Results] Check if pool success",
  props<{ synlabId: string, isPool: boolean }>()
);
const checkIfPoolFailure = createAction(
  "[Results] Check if pool failure",
  props<{ synlabId: string, error: string }>()
);
const getLabAddress = createAction(
  "[Results] Get laboratory address",
  props<{ senderIdentifierCode: string }>()
);
const getLabAddressSuccess = createAction(
  "[Results] Get laboratory address success",
  props<{ senderIdentifierCode: string, laboratoryAddress: string }>()
);
const getLabAddressFailure = createAction(
  "[Results] Get laboratory address failure",
  props<{ senderIdentifierCode: string, error: string }>()
);
const getResultAddress = createAction(
  "[Results] Get result address",
  props<{ synlabId: string }>()
);
const getResultAddressSuccess = createAction(
  "[Results] Get result address success",
  props<{ synlabId: string, data: { address: string, passportNumber: string; } }>()
);
const getResultAddressFailure = createAction(
  "[Results] Get result address failure",
  props<{ synlabId: string, error: string }>()
);
export const ResultActions = {
  getResultAddress,
  getResultAddressSuccess,
  getResultAddressFailure,
  stopForceLoading,
  forceLoadCertificate,
  loadInvoice,
  loadInvoiceSuccess,
  loadInvoiceFailed,
  loadLabResults,
  loadLabResultsSuccess,
  loadLabResultsFailed,
  loadCheckResultsFailed,
  loadCheckResultsSuccess,
  loadCheckResults,
  loadSelectedUser,
  loadSelectedUserSuccess,
  loadSelectedUserFailed,
  loadSelectedUserSynlabId,
  loadSelectedUserSynlabIdSuccess,
  loadSelectedUserSynlabIdFailed,
  loadPdfPreview,
  downloadFileSuccess,
  downloadFileFailure,
  downloadFile,
  loadTestTypeListFailure,
  loadTestTypeListSuccess,
  loadTestTypeList,
  loadResultssFailure,
  loadResultssSuccess,
  loadResultss,
  clearLoadSchool,
  loadSchool,
  loadSchoolSuccess,
  loadSchoolFailed,
  selectSchool,
  LoadTeacherCancel,
  loadTeacher,
  fetchTeacherSuccess,
  loadTeacherSuccess,
  loadTeacherFailed,
  setTeacherQuery,
  selectTeacher,
  LoadPoolCancel,
  loadPool,
  fetchPoolSuccess,
  loadPoolSuccess,
  loadPoolFailed,
  setPoolQuery,
  selectPool,
  checkIfPool,
  checkIfPoolSuccess,
  checkIfPoolFailure,
  getLabAddress,
  getLabAddressSuccess,
  getLabAddressFailure,
  ...companyCheckActions
};
