import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable, iif, of, forkJoin } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { mergeMap, take, map } from "rxjs/operators";
import { ConfigService } from "../config/config.service";
import { UserClaimsService } from "../user-claims.service";

@Injectable({
  providedIn: "root",
})
export class CountryGuard  {
  constructor(
    private authService: AuthService,
    private configService: ConfigService,
    private router: Router,
    public userClaims: UserClaimsService
  ) {}

  /**
   * check if logged in
   *  if not logged in, throw error and return false
   *    because it means authguard didnt run yet even
   *    though it should have been setup like that.
   *  if logged in, continue because you know you can get country
   * get country
   * get env config to get base url
   * if type is init, redirect to " envconfigBaseResultUrl / authcountry "
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService
      .isLoggedInObs()
      .pipe(
        mergeMap((isLoggedIn) =>
          iif<boolean, boolean>(
            () => isLoggedIn,
            this.CheckCountry(),
            of(false)
          )
        )
      );
  }
  /** Check if user should be redirected to route with country.
   * Redirect when needed.
   */
  CheckCountry(): Observable<boolean> {
    return forkJoin([
      this.configService.getEnvironmentConfig().pipe(take(1)),
      this.userClaims.country$.pipe(take(1))
    ]).pipe(
      map(([env, country]) => {
        if (env.Type === this.configService.TypeValueRedirect) {
          this.router.navigate(["/"]);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
