import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/service/api/api.service";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/service/auth/auth.service";
import { UnauthorizePromptService } from "src/app/service/auth/unauthorize-prompt/unauthorize-prompt.service";
import { DataService } from "src/app/service/data/data.service";
import { UserClaimsService } from "src/app/service/user-claims.service";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.scss"],
})
export class IndexComponent implements OnInit, OnDestroy {
  apiRequest: any = [];
  ssn: string;
  countryVal;
  availableCountries: any = [];
  userCountries: any = [];
  patientData = {
    given_name: "",
    family_name: "",
    user_type: "patient",
    //"country/locale": "",
    country: "",
    locale: "hu-HU",
    email: "",
    date_of_birth: "12/12/2019",
    ssn: "",
  };

  constructor(
    private router: Router,
    private api: ApiService,
    private toastr: ToastrService,
    private auth: AuthService,
    private unAuth: UnauthorizePromptService,
    private actRoute: ActivatedRoute,
    public data: DataService,
    public userClaims: UserClaimsService
  ) {
    this.getFullCountryLabel();
    this.fetchCountries();
    this.fetchCountry();

    this.userClaims
      .checkUserAccess(this.actRoute.snapshot.data.allowedUsers || [])
      .subscribe((allowed) => {
        if (!allowed) { this.unAuth.showPopup(); }
      });
  }

  ngOnInit() {
    this.fetchCountry();

    this.auth.userInfo.subscribe((d) => {
      console.log(d);
      if (d) {
        if (d.country != null) {
          this.userCountries =
            typeof d.country === "string" ? [d.country] : d.country;
        } else {
          this.userCountries =
            typeof d["country/locale"] === "string"
              ? [d["country/locale"]]
              : d["country/locale"];
        }
        console.log(this.userCountries);
      }
    });
  }

  fetchCountry() {
    this.data.currentCountryValue.subscribe((val) => {
      console.log(this.data.selectedCountry); //object
      console.log(this.selectedCountry); //HU
    });
  }

  getFullCountryLabel() {
    this.api.get("/manage/country").subscribe((c) => {
      this.availableCountries = c;
      if (this.country.length) {
        // set initial value
        this.data.changeValue(this.country[0]);
      }
      // this.userCountries = this.userCountry
    });
  }

  get country() {
    //**must detect why userCountries is in lower case
    return this.availableCountries.filter((d) => {
      if (this.userCountries) {

        if (
          this.userCountries.includes(`${d.twoLetterCountryCode}`.toLowerCase())
        )
          return true;
      }
    });
  }

  get selectedCountry() {
    return `${this.data.selectedCountry.twoLetterCountryCode}`;
  }

  ngOnDestroy() {
    this.cancelAllApiSubscriptions();
  }

  back() {
    this.router.navigate(["/"]);
  }

  fetchCountries() {
    this.api.get(`/manage/country`).subscribe(
      (d) => {
        this.availableCountries = d;
      },
      (e) => {
        console.log(e);
      }
    );
  }

  get patientInfo() {
    return Object.keys(this.patientData).map((d) => {
      //if(d == "country/locale")
      if (d == "country")
        return {
          claimType: d,
          ClaimValue: this.selectedCountry,
        };

      return {
        claimType: d,
        ClaimValue: this.patientData[d],
      };
    });
  }

  get countryLabel() {
    return this.availableCountries
      .filter((d) => {
        return (
          `${d.twoLetterCountryCode};${d.cultureCode}` ==
          this.patientData.country
        );
      })
      .map((d) => {
        return d.countryName;
      })
      .join();
  }

  createPatient() {


    this.cancelAllApiSubscriptions();
    let user = this.patientInfo;
    console.log(user);
    const req = this.api.post("/manage/accounts", user).subscribe(
      (response) => {
        this.toastr.success(
          "Successfully Created Patient!",
          "User has been created"
        );
        this.router.navigate(["/home"]);
      },
      (e) => {
        this.toastr.error(e.error.ErrorMessage);
        console.log(e);
      }
    );
    this.apiRequest.push(req);
  }

  cancelAllApiSubscriptions() {
    if (this.apiRequest.length) {
      this.apiRequest.forEach((api) => {
        api.unsubscribe();
      });
    }
  }
}
