import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RedirectService {
  redirectTo(url: string) {
    window.location.href = url;
  }
}
