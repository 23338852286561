<div data-test-id="app-navbar"  class="navbar-wrapper" id="navbar-wrapper">
    <syn-synlab-header-switch-profile data-test-id="app-header-switch"  *ngIf="useNewNav$| async" (onLogout)="logout()"></syn-synlab-header-switch-profile>
</div>

<div data-test-id="app-header-wrap"  class="page-header-wrap">
  <app-header data-test-id="app-app-header"  *ngIf="!(useNewNav$| async)"></app-header>
  <div data-test-id="app-progress"  class="progress"  *ngIf="loadingBar$ | async">
    <div data-test-id="app-progressbar"  class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"></div>
  </div>
</div>
<div data-test-id="app-content-wrap"  class="page-content-wrap" [class.ee-tenant]="isEETenant$|async">
  <div data-test-id="app-container"  class="s-container">
    <router-outlet data-test-id="app-router-outlet" ></router-outlet>
  </div>
</div>

<app-popup-unauthorized data-test-id="app-popup-unauthorized" ></app-popup-unauthorized>
